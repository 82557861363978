import { HistoryTableLayoutEnum } from '@/components/historyModel/layout';
import { APIModel, IBaseRequestData } from '../apiRequest';
import { ReportExportEnum, ReportPageEnum } from '../transfer/reportTransfer';
import { IDateRangePickerProps } from '@/components/dateRangePicker';
import { SortingStatus } from '../sorting';
import { PermissionsId } from '@/models/permissions';

export type TableLayoutConfig = {
    pageName: string;
    pageId: ReportPageEnum;
    permissionId: PermissionsId;
    api?: TableLayoutAPI;
    direactableKeyList?: DireactableKeyList[];
    menuVisible?: boolean;
    headerType: TableHeaderType;
    search?: TableSearch[] | TableSearch[][];
    headerText?: TableHeaderTextList[];
    isShowPagging: boolean;
    needFold?: boolean;
    noteComp?: () => JSX.Element;
};

export type ExportConfig = {
    exportpi: APIModel;
    exportType: ReportExportEnum;
};

export type TableHeaderTextList = {
    title: string;
    key: string;
    keyRef?: keyof ITableLayoutData;
    refObj?: string;
    type: TableColumnStyle;
};

export type TableLayoutAPI = {
    path: string;
    method: string;
    initParams: IBaseRequestData;
    beforeTransfer?: (responseData: any, payloadFromPreDireactablePage: { key: string; value: string }[] | undefined) => any[];
    insertParamsBeforeFetch?: (request: IBaseRequestData, payloadFromPreDireactablePage: { key: string; value: any }[] | undefined) => IBaseRequestData;
};

export type DireactableKeyList = {
    key: string;
    directLayout?: ReportPageEnum;
    isPopupModel?: boolean;
    showPopupPermission?: PermissionsId;
    payload?: {
        forTitleLabelKey?: string;
        forBeforeFetch?: string[];
        forBeforeTrensfer?: string[];
    };
};

export enum TableColumnStyle {
    Text,
    Date,
    NumericText,
    Numeric,
    CommaNumeric,
    CommaNumericWithSymbol,
    CommaNumericWithNegativeSymbol,
    CommaNumericWithIcon,
    Percentage,
    DateTime,
    DateMonth,
    Partner,
    TextI18N,
    TextI18NWithColor,
    TextWithColor,
    TextWithCopy,
    Remark,
    Icon,
    VIPRank,
    CommaNumericWithText,
}

export enum TableCalculateType {
    None,
    Plus,
    Minus,
    Multiply,
    Divides,
}

export type TableColumn = {
    name: string;
    itemKeys: string[];
    calculateType: TableCalculateType;
    type: TableColumnStyle;
    toFixed?: number;
};

export type DirectableItem<T, R = null> = {
    key: string;
    isPopupModel?: boolean;
    directLayout?: T;
    data?: R;
};

export type TableExpandType = 'text' | 'multiLine-text' | 'link';
export interface TableExpandFetchData {
    src: APIModel;
    requestKey: string[];
    layoutId: HistoryTableLayoutEnum;
}

export type TableExtraDataList = {
    value: string | number;
    alignment?: 'text-left' | 'text-right' | 'text-center';
    type: TableColumnStyle;
};

export type TableExtraData = {
    dataList: TableExtraDataList[];
};

export type TableExpandBody = {
    columnKey: string;
    value: string | number | boolean;
    type: TableExpandType;
    requestData?: TableExpandFetchData;
    className?: string;
};

export enum ExtraTableDataType {
    HEADER,
    BODY,
    FOOTER,
}

export interface ITableLayoutData {
    tableHeader: ITableColumnData[];
    tableBody: ITableBodyLayoutData[];
    tableExpandBody?: TableExpandBody[][];
    tableFooter: ITableBodyLayoutData;
    totalRecords: number;
    hasFooter: boolean;
    switcherMode?: any;
    extraData?: any;
    extraTableData?: { headerList: { key: ExtraTableDataType; value: string }[]; data: TableExtraData[] };
    sortableFields?: { sortKey: number; name: string; status: SortingStatus }[];
}

export interface ITableColumnData {
    name: string;
    text: string;
    type: TableColumnStyle;
    invisible?: boolean;
    switch?: number;
}
export interface ITableBodyLayoutData {
    [key: string]: string | number;
}
export interface ITableSummaryLayoutData {}

export type TableConfigColumn<T> = {
    name: string;
    text: string;
    itemKeys: string[];
    type: TableColumnStyle;
    calculate?: (data: T, keys: string[]) => number | string;
    invisible?: boolean;
    switchId?: number;
    sortKey?: number;
};

export type TableConfig<T> = {
    column: TableConfigColumn<T>[];
    footer: string[];
};

export enum TableHeaderType {
    NONE,
    TEXT,
    SEARCH,
    SWITCHER,
    SEARCH_WITH_TEXT,
    SEARCH_COLUMN,
    SEARCH_WITH_TABLE,
}

export type TableSearchOption = {
    id: number;
    text: string;
    withoutI18n?: boolean;
};

export type TableSearch = {
    type: TableSearchEnum;
    dateMatchKey?: {
        startDate: string;
        endDate: string;
        shortcuts?: IDateRangePickerProps['shortcuts'];
    };
    minDate?: string;
    rangeMatchKey?: { start: string; end: string };
    matchKey?: string;
    label: string;
    placeholder?: string;
    selectOptions?: TableSearchOption[];
    defaultSelectOptions?: number[];
    rangeSelectOptions?: { start: TableSearchOption[]; end: TableSearchOption[] };
    hasFuzzy?: boolean;
    fuzzyKey?: string;
    shortcuts?: boolean;
};

export enum TableSearchEnum {
    NONE,
    DATE_RANGE,
    DATE_RANGE_MONTH,
    INPUT,
    SELECT,
    MULTI_SELECT,
    MULTI_SELECT_CUSTOME,
    RANGE_SELECT,
}
